<template>
  <div>
    <template slot="title">
      <div>
        <span class="p-modal-header-title">
          Filter
        </span>
      </div>
    </template>
    <a-form :layout="formLayout" @submit="handleSubmit">
      <a-row>
        <a-col :span="24">
          <a-form-item label="Status">
            <a-checkbox
              :style="checkboxStyle"
              :checked="statusAll"
              :indeterminate="statusIndeterminate"
              @change="onCheckedAllOptionsStatus"
              :value="['active', 'inactive', 'pending']"
            >
                Pilih Semua
              </a-checkbox>
            <a-checkbox-group v-model="statusCheckbox" @change="onChangeFilterStatus">
              <a-checkbox :style="checkboxStyle" value="active">
                Terverifikasi
              </a-checkbox>
              <a-checkbox :style="checkboxStyle" value="inactive">
                Tidak Terverifikasi
              </a-checkbox>
              <a-checkbox :style="checkboxStyle" value="pending">
                Verifikasi Tertunda
              </a-checkbox>
            </a-checkbox-group>
          </a-form-item>
        </a-col>
      </a-row>
      <a-form-item>
        <div class="p-modal-button-container">
          <a-button
            class="p-modal-cancel"
            size="large"
            @click="handleCancel"
          >
            Batal
          </a-button>
          <a-button
            type="primary"
            size="large"
            html-type="submit"
            class="p-modal-submit"
          >
            Filter
          </a-button>
        </div>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      confirmLoading: false,
      formLayout: "vertical",
      value: 1,
      checkboxStyle: {
        display: "block",
        lineHeight: "24px",
        marginLeft: "0px",
      },
      paramCompanies: "",
      paramStatus: "",
      companiesAll: false,
      companyIndeterminate: false,
      statusAll: false,
      statusIndeterminate: false,
      companiesCheckbox: [],
      statusCheckbox: [],
    };
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      const params = [this.paramCompanies, this.paramStatus].join("&");
      this.$emit("filter", params);
    },
    onChangeFilterStatus(checkedValues) {
      const paramStatus = checkedValues.map((value) => `status[]=${value}`);
      this.paramStatus = paramStatus.join("&");
      this.statusIndeterminate = !!checkedValues.length && checkedValues.length < 3;
      this.statusAll = checkedValues.length === 3;
    },
    onCheckedAllOptionsStatus({ target }) {
      Object.assign(this, {
        statusCheckbox: target.checked ? target.value : [],
        statusIndeterminate: false,
        statusAll: target.checked,
      });
      this.paramStatus = "";
    },
    handleCancel() {
      this.$emit("handleCancel");
    },
  },
});
</script>
